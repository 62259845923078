import './boxicon.css'

export default function BoxIcon({color,icon}:any) {
    const style = {
        color:color,
        background:color+"20"
    }
    return (
        <div className='box_icon' style={style}>
            {icon}
        </div>
    )
}
