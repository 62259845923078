
export default function Decrease({data}:any) {
    return (
        <div className='change decrease'>
            <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.50009 13V4.57764e-05L6.75009 3.00005L4.00009 4.57764e-05V13H0.765652C0.492784 13 0.36161 13.3348 0.56183 13.5202L6.47833 18.9984C6.63168 19.1404 6.8685 19.1404 7.02185 18.9984L12.9384 13.5202C13.1386 13.3348 13.0074 13 12.7345 13H9.50009Z" fill="#F2346B"/>
            </svg>
            <span>{data.toFixed(2) +' %'}</span>
        </div>
    )
}
