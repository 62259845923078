import React,{useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import './statestates.css'
// import IndiaData from '../../projected_maps/india.json'
// import WestBengal from '../../projected_maps/westbengal.json'
import {IoArrowBack} from 'react-icons/io5'
import ListData from './ListData';
import FilterBox from '../FilterBox/FilterBox';

export default function Statedistrict({setDateRange,loading,togglemode,setStateSelected,data,setData,setStats,city,setCity}:any) {
  const params = useParams()
  const [filterbox,setFilterBox] =useState("all_time");
  useEffect(()=>{
    let day:number = 0;
      
      if(filterbox==="today"){
        day = 0*86400000
      }
      if(filterbox==="one_day"){
        day = 1*86400000
      }
      if(filterbox === "one_week"){
        day = 7*86400000
      }
      if(filterbox==="one_month"){
        day = 30*86400000
      }
      if(filterbox==="three_months"){
        day = 90*86400000
      }
      if(filterbox==="six_months"){
        day = 180*86400000
      }
      if(filterbox==="one_year"){
        day = 365*86400000
      }
      if(filterbox==="five_years"){
        day = 365*5*86400000
      }
      if (filterbox === "all_time") {
        // Set the start date to August 6th 2016
        setDateRange([new Date("2016-08-06"), new Date()]);
        } else {
        // Set the start date based on the day variable
        setDateRange([new Date(new Date().getTime() - day), new Date()]);
        }
  },[filterbox])

  const setBack = (e:any)=>{

    // On back click state name will set as default and city will be null
    // but here is occuring actual issue

    setStats(params.statename)
    setCity(null);
    setData(null)
    setStateSelected(null)
    // setChartData(null)
  }
  const setFilter = (e:any)=>{
    let tag = e.target.tagName
    let classname;
    if(tag==="svg" || tag==="SPAN"){
      classname = e.target.parentElement.className
    }else{
      classname = e.target.className;
    }
    if(classname!==filterbox+" active"){
      setFilterBox(classname)
    }
    classname = classname.split(" active")[0]
    if(classname==="one_week" || classname==="one_month" || classname==="three_months"||classname==="six_months"||classname==="one_year"||classname==="five_years" || classname==="one_day" || classname==="today"){
      setFilterBox(classname)
    }
    // console.log(Tempdata)
  }
  return (
    <div className={togglemode==="1"?'statedisstat mx-0':'statedisstat light mx-0'}>
      <div className="statehead">
        {/* State name */}
        {city===null || city===undefined?<p className={params.statename===undefined?'':'mb-1'}>Demographics of <b>{params.statename===undefined?"India":params.statename}</b></p>:
        <div className="city_name">
          
          <IoArrowBack style={{"cursor":"pointer"}}  onClick={setBack}/>
          <p>{city}</p>
        </div>
        }
        {city && params.statename!==undefined || params.statename ? <FilterBox filterbox={filterbox} setFilter={setFilter}/>: null}
        {/* <FilterBox filterbox={filterbox} setFilter={setFilter}/> */}
      </div>
      <div className="statedist" style={params.statename===undefined?{}:{"height":"-webkit-fill-available"}}>
        <ListData loading={loading} filterbox={filterbox} togglemode={togglemode} setStateSelected={setStateSelected} setCity={setCity} city={city} data={data} setStats={setStats}/>
      </div>
    </div>
  )
}
