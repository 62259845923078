import React from 'react'
import Decrease from '../CountChange/Decrease'
import Increase from '../CountChange/Increase'
import Loader from '../Loader/Loader';
import { v4 as uuidv4 } from "uuid";
type paramPass = {
    togglemode:String,
    showData:any,
    dataShowType:String,
    setDataShowType:any,
    setSelectState:any,
    setSelectDistrict:any,
    setSelectedDataType:any,
    setSelectCity:any,
    loading:Boolean
}
export default function ListsData({loading,togglemode,showData,dataShowType,setDataShowType,setSelectState,setSelectDistrict,setSelectedDataType,setSelectCity}:paramPass) {
    const toCapitalize = (string:String)=>{
        let list = string.split(" ")
        for(let i in list){
            list[i] = list[i].charAt(0) + list[i].slice(1).toLowerCase()
        }
        return list.join(" ");
    }
    const selectData = (data:String)=>{
        if(dataShowType==="Country"){
            setDataShowType("State");
            setSelectState(data);
        }
        if(dataShowType==="State"){
            setDataShowType("District");
            setSelectDistrict(data)
        }
        if(dataShowType==="District"){
            setDataShowType("City");
            setSelectCity(data)
        }
        if(dataShowType==="City"){
            setSelectCity(data)
        }
        setSelectedDataType(data)
    }
    return (
        <>
            <div id="table-scroll" className="table-scroll">
                {loading?
                    <div className='d-flex justify-content-center'>
                        <Loader />
                    </div>
                    :
                    <table id="main-table" className="main-table">
                        <thead>
                            <tr>
                                <th scope="col">{showData?showData.meta.region.name:null}</th>
                                {showData?Object.keys(showData.meta).map((m:any,ind)=>{
                                    return (
                                        <>
                                            {m==="region" || m==="percentage" || m==="totalGradable"?null:
                                                <th key={uuidv4()} scope="col"><span>{showData.meta[m]}</span></th>
                                            }
                                        </>
                                    )
                                }):null}
                            </tr>
                        </thead>
                        <tbody>

                            {showData?
                                showData.data.map((st:any,ind:any)=>{
                                    return(
                                        <tr key={uuidv4()} onClick={()=>selectData(toCapitalize(st.region.name))}>
                                            <th>{toCapitalize(st.region.name)}</th>
                                            {Object.keys(showData.meta).map((d:any)=>{
                                                return (
                                                    <>
                                                        {d!=="region" &&d!==undefined && d!=="percentage" && d!=="totalGradable"?
                                                            
                                                            <td key={uuidv4()}>
                                                                <div className="table_data">
                                                                    {st.percentage[d]<0?<Decrease data={st.percentage[d]} />:st.percentage[d]>0?<Increase data={st.percentage[d]} />:null}
                                                                    <p>{st[d]}</p>
                                                                </div>
                                                            </td>
                                                        :null}
                                                    </>
                                                )
                                            })}
                                        </tr>
                                    )
                                })
                            :null}
                        </tbody>
                    </table>
                }
            </div>
        </>
    )
}
