import React,{useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import StateVisualizer from '../../components/StateVisualizer/StateVisualizer'
import Statedistrict from '../../components/Statstates/Statedistrict'
import StatBox from '../../components/Statstates/StatBox'
// import WestBengal from '../../../projected_maps/westbengal.json'
import './state.css'
// import SearchBar from '../../SearchBar/SearchBar'

import {DistrictState} from '../../data/DistrictOfState'
import axios from 'axios'
import ErrorPage from '../ErrorPage/ErrorPage'
import Loader from '../../components/Loader/Loader'

export default function State({togglemode,setPath}:any) {
  let day = 365*5*86400000;
  const params = useParams();
  const [statename] = useState<any>(params.statename)
  const [stats,setStats] = useState<any>(params.statename);
  const [ismapname,setMapName] = useState("scans");
  const [stateselected,setStateSelected] = useState(null);
  const [city,setCity] = useState<any>(null);
  const [data,setData] = useState<any>(null)
  const [loading,setLoading] = useState<Boolean>(false);
  const [pageLoading,setPageLoading] = useState<Boolean>(true);
  const [dateRange, setDateRange] = useState<any>([new Date(new Date().getTime() - day),new Date()]);
  const [selected,setSelected] = useState<String | null>(null);
  const [error,setError] = useState<any>(null);
  // const [StateDistrict,setStateDistrict] = useState<any>(null)
  // setMapName={setMapName} ismapname={ismapname} stats={stats}
  //stateselected={stateselected} setStateSelected={setStateSelected} setMapName={setMapName} ismapname={ismapname} stats={stats} setStats={setStats}
  const StateDistrict = DistrictState[statename.split(" ").join("")];
  // useEffect(()=>{
  //   setStateDistrict(DistrictState[stats.split(" ").join("")])
  // },[statename])
  useEffect(()=>{
    setPath(params)
    // console.log(params.statename)
  },[])
  
  useEffect(()=>{
    const syear = dateRange[0].toLocaleString("default",{year:"numeric"});
    const smonth = dateRange[0].toLocaleString("default",{month:"2-digit"});
    const sday = dateRange[0].toLocaleString("default",{day:"2-digit"});
    const startDate = syear+"-"+smonth+"-"+sday

    const eyear = dateRange[1].toLocaleString("default",{year:"numeric"});
    const emonth = dateRange[1].toLocaleString("default",{month:"2-digit"});
    const eday = dateRange[1].toLocaleString("default",{day:"2-digit"});
    const endDate = eyear+"-"+emonth+"-"+eday
    let url = process.env.REACT_APP_API;
    if(city===null || city===undefined ){
      // setting data for state page
      // Call API based on State
      url =url + `/data/list?country=India&state=${statename}&startDate=${startDate}&endDate=${endDate}&level=District`
      
    }else{
      // setting data for cities
      // Call API based on district
      url =url + `/data/list?country=India&state=${statename}&district=${city}&startDate=${startDate}&endDate=${endDate}&level=City`
    }
    if(url!==undefined){
      setLoading(true)
      axios.get(url).then((res)=>{
        setData(res.data.result);
        setLoading(false)
      }).catch((error)=>{
        setError(error)
      })
    }
  },[city,dateRange])
  useEffect(()=>{
    if(data && selected && stats!=="India" && stats!==params.statename){
      let tempData = data.data.filter((name:any)=>{
        return name.region.name === selected;
      })
      setStats(tempData[0])
      setLoading(false)
    }
  },[selected,data])
  useEffect(()=>{
    if(loading){
      setTimeout(() => {
        setPageLoading(false)
      }, 1000);
    }

  },[loading])
  if(error){
    return (
      <ErrorPage error={error?.message??"Error"} />
    )
  }
  if(pageLoading){
    return (
      <div className='d-flex flex-column justify-content-center align-items-center' style={{height:"calc(100vh - 10.5rem)"}}>
          <Loader />
          <p className='mt-3'>Loading... Wait for the data</p>
      </div>
    )
  }
  return (
    <>
        <div className='state_page p-0 row m-0'>
          <div className="home_stats col-xl-6 col-sm-12">
            <div className="stats_show">
              <StatBox dateRange={dateRange} selected={selected} setLoading={setLoading}  setStats={setStats} loading={loading} city={city} CountryStateData={StateDistrict} type={"Districts"} setMapName={setMapName} ismapname={ismapname} stats={stats}/>
              <StateVisualizer city={city} loading={loading} setSelected={setSelected} StateDistrict={StateDistrict} setData={setData} togglemode={togglemode} setCity={setCity} stateselected={stateselected} setStateSelected={setStateSelected} setMapName={setMapName} ismapname={ismapname} stats={stats} setStats={setStats} data={data}/>
            </div>
          </div>
          <div className="state_stats col-xl-6 col-sm-12">
              <Statedistrict setDateRange={setDateRange} loading={loading}  stateselected={stateselected} togglemode={togglemode} setStateSelected={setStateSelected} data={data} setData={setData} setCity={setCity} city={city} setStats={setStats} />
          </div>
        </div>
    </>
  )
}
