import React, { useState, useEffect } from "react";
import Statedistrict from "../../components/Statstates/Statedistrict";
import StatBox from "../../components/Statstates/StatBox";
import CountryVisualizer from "../../components/CountryVisualizer/CountryVisualizer";
import "./home.css";
import SearchBar from "../../components/SearchBar/SearchBar";
import { useParams } from "react-router-dom";
// import IndiaData from '../../../projected_maps/india.json'
import CountryStateData from "../../data/StatesOfCountry.json";
import axios from "axios";
import ErrorPage from "../ErrorPage/ErrorPage";
import Loader from "../../components/Loader/Loader";
import FilterBox from "../../components/FilterBox/FilterBox";

export default function Home({ togglemode, setPath }: any) {
    let day = 365 * 5 * 86400000;
    const params = useParams();
    const [stats, setStats] = useState<any>("India");
    const [ismapname, setMapName] = useState("scans");
    const [stateselected, setStateSelected] = useState(null);
    const [data, setData] = useState<any>(null);
    const [Tempdata, setTempData] = useState<any>(null);
    const [order, setOrder] = useState<String | any>("home_stats");
    const [pageLoading, setPageLoading] = useState<Boolean>(true);
    const [dateRange, setDateRange] = useState<any>([
        new Date(new Date().getTime() - day),
        new Date(),
    ]);
    const [loading, setLoading] = useState<Boolean>(false);
    const [selected, setSelected] = useState<String | null>(null);
    const [error, setError] = useState<any>(null);
    const [mapSelection, setMapSelection] = useState<String>("static_view");
    // setMapName={setMapName} ismapname={ismapname} stats={stats}
    //stateselected={stateselected} setStateSelected={setStateSelected} setMapName={setMapName} ismapname={ismapname} stats={stats} setStats={setStats}
    // useEffect(() => {
    //   setTempData(CountryStateData.result.data.slice())
    // }, [data])
    const [filterbox, setFilterBox] = useState("all_time");
    useEffect(() => {
        let day: number = 0;

        if (filterbox === "today") {
            day = 0 * 86400000;
        }
        if (filterbox === "one_day") {
            day = 1 * 86400000;
        }
        if (filterbox === "one_week") {
            day = 7 * 86400000;
        }
        if (filterbox === "one_month") {
            day = 30 * 86400000;
        }
        if (filterbox === "three_months") {
            day = 90 * 86400000;
        }
        if (filterbox === "six_months") {
            day = 180 * 86400000;
        }
        if (filterbox === "one_year") {
            day = 365 * 86400000;
        }
        if (filterbox === "five_years") {
            day = 365 * 5 * 86400000;
        }
        if (filterbox === "all_time") {
            // Set the start date to August 6th 2016
            setDateRange([new Date("2016-08-06"), new Date()]);
            } else {
            // Set the start date based on the day variable
            setDateRange([new Date(new Date().getTime() - day), new Date()]);
            }
    }, [filterbox]);
    useEffect(() => {
        setPath(params);
        const syear = dateRange[0].toLocaleString("default", {
            year: "numeric",
        });
        const smonth = dateRange[0].toLocaleString("default", {
            month: "2-digit",
        });
        const sday = dateRange[0].toLocaleString("default", { day: "2-digit" });
        const startDate = syear + "-" + smonth + "-" + sday;

        const eyear = dateRange[1].toLocaleString("default", {
            year: "numeric",
        });
        const emonth = dateRange[1].toLocaleString("default", {
            month: "2-digit",
        });
        const eday = dateRange[1].toLocaleString("default", { day: "2-digit" });
        const endDate = eyear + "-" + emonth + "-" + eday;
        let url =
            process.env.REACT_APP_API +
            `/data/list?country=India&startDate=${startDate}&endDate=${endDate}&level=State`;
        if (url !== undefined) {
            setLoading(true);
            axios
                .get(url)
                .then((res) => {
                    setData(res.data.result);
                    setLoading(false);
                })
                .catch((error) => {
                    setError(error);
                });
        }
        // setData(CountryStateData.result)
        if (params.statename === undefined) {
            setOrder("home_stats hm-or2");
        } else {
            setOrder("home_stats");
        }
    }, [dateRange]);
    useEffect(() => {
        if (data && selected && stats !== "India") {
            let tempData = data.data.filter((name: any) => {
                return name.region.name === selected;
            });
            setStats(tempData[0]);
            setLoading(false);
        }
    }, [selected, data]);
    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setPageLoading(false);
            }, 1000);
        }
    }, [loading]);
    const setFilter = (e: any) => {
        let tag = e.target.tagName;
        let classname;
        if (tag === "svg" || tag === "SPAN") {
            classname = e.target.parentElement.className;
        } else {
            classname = e.target.className;
        }
        if (classname !== filterbox + " active") {
            setFilterBox(classname);
        }
        classname = classname.split(" active")[0];
        if (
            classname === "one_week" ||
            classname === "one_month" ||
            classname === "three_months" ||
            classname === "six_months" ||
            classname === "one_year" ||
            classname === "five_years" ||
            classname === "one_day" ||
            classname === "today" ||
            classname === "all_time"
        ) {
            setFilterBox(classname);
        }
        // console.log(Tempdata)
    };
    if (error) {
        return <ErrorPage error={error?.message ?? "Error"} />;
    }
    if (pageLoading) {
        return (
            <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{ height: "calc(100vh - 10.5rem)" }}
            >
                <Loader />
                <p className="mt-3">Loading... Wait for the data</p>
            </div>
        );
    }
    return (
        <>
            <div className="home p-0 row m-0">
                <div className={"col-xl-6 col-sm-12 " + order}>
                    <div className="stats_show">
                        <div className="d-flex">
                            <FilterBox
                                filterbox={filterbox}
                                setFilter={setFilter}
                            />
                        </div>
                        <SearchBar data={data} togglemode={togglemode} />
                        <StatBox
                            mapSelection={mapSelection}
                            dateRange={dateRange}
                            setLoading={setLoading}
                            setStats={setStats}
                            loading={loading}
                            CountryStateData={CountryStateData}
                            type={"States"}
                            setMapName={setMapName}
                            ismapname={ismapname}
                            stats={stats}
                        />
                        <Statedistrict
                            setDateRange={setDateRange}
                            loading={loading}
                            CountryStateData={CountryStateData}
                            togglemode={togglemode}
                            Tempdata={Tempdata}
                            data={data}
                            setData={setData}
                            setStats={setStats}
                        />
                    </div>
                </div>
                <div className="home_stats col-xl-6 col-sm-12">
                    <CountryVisualizer
                        mapSelection={mapSelection}
                        setMapSelection={setMapSelection}
                        dateRange={dateRange}
                        loading={loading}
                        data={data}
                        setSelected={setSelected}
                        togglemode={togglemode}
                        stateselected={stateselected}
                        setStateSelected={setStateSelected}
                        setMapName={setMapName}
                        ismapname={ismapname}
                        stats={stats}
                        setStats={setStats}
                    />
                </div>
            </div>
        </>
    );
}
