import React,{useState} from 'react'
import {Link} from 'react-router-dom'

export default function Links({isstates,isstarted,ishome}:any) {
    const [linksname,setLinksName] = useState(false);
    
    const displayLinksName=()=>{
        setLinksName(true);
    }
    const hideLinksName=()=>{
        setLinksName(false);
    }
  return (
    <>
        <div className="links" onMouseOver={displayLinksName} onMouseOut={hideLinksName}>
            <Link className={ishome?'active':''} to="/">
            <img src="home.png" alt="" />
            </Link>
            <Link className={isstarted?'active':''} to="/getstarted">
            <img src="getstart.png" alt="" />
            </Link>
            <div className={linksname?"linksname active":"linksname inactive"}>
                <Link className={ishome?'active':''} to="/">
                    Home
                </Link>
                <Link className={isstarted?'active':''} to="/getstarted">
                    Get Started
                </Link>
            </div>
        </div>
    </>
  )
}
