import WestBengal from './westbengal.json'
import Assam from './assam.json'
import UttarPradesh from './uttarpradesh.json'
import AndamanAndNicobarIslands from './andamannicobarislands.json';
import AndhraPradesh from './andhrapradesh.json';
import ArunachalPradesh from './arunachalpradesh.json';
import Bihar from './bihar.json';
// import Chandigarh from './chandigarh.json';
import Chhattisgarh from './chhattisgarh.json';
import Delhi from './delhi.json';
// import DnhDd from './dnh-and-dd.json';
import Goa from './goa.json';
import Gujarat from './gujarat.json';
import Haryana from './haryana.json';
import HimachalPradesh from './himachalpradesh.json';
import JammuAndKashmir from './jammukashmir.json';
import Jharkhand from './jharkhand.json';
import Karnataka from './karnataka.json';
import Kerala from './kerala.json';
import Ladakh from './ladakh.json';
import Lakshadweep from './lakshadweep.json';
import MadhyaPradesh from './madhyapradesh.json';
import Maharashtra from './maharashtra.json';
import Manipur from './manipur.json';
import Meghalaya from './meghalaya.json';
import Mizoram from './mizoram.json';
import Nagaland from './nagaland.json';
import Odisha from './odisha.json';
import Puducherry from './puducherry.json';
import Punjab from './punjab.json';
import Rajasthan from './rajasthan.json';
import Sikkim from './sikkim.json';
import TamilNadu from './tamilnadu.json';
import Telangana from './telangana.json';
import Tripura from './tripura.json';
import Uttarakhand from './uttarakhand.json';

export const Maps:any = {
    WestBengal,
    Assam,
    UttarPradesh,
    TamilNadu,
    Kerala,
    AndamanAndNicobarIslands,
    Lakshadweep,
    Bihar,
    Maharashtra,
    Karnataka,
    Ladakh,
    Sikkim,
    Meghalaya,
    Tripura,
    Mizoram,
    Manipur,
    Nagaland,
    ArunachalPradesh,
    Jharkhand,
    Odisha,
    Chhattisgarh,
    AndhraPradesh,
    Telangana,
    MadhyaPradesh,
    Goa,
    Gujarat,
    Rajasthan,
    Haryana,
    Punjab,
    HimachalPradesh,
    JammuAndKashmir,
    Uttarakhand,
    Delhi,
    Puducherry
}