import React,{useState,useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route,useParams } from "react-router-dom";
import Sidebar from './components/Sidebar/Sidebar';
import Home from './pages/Home/Home';
import State from './pages/State/State';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
// import ComponentsPage from './pages/DummyPage/ComponentsPage'
import Dashboard from './pages/Dashboard/Dashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-tooltip/dist/react-tooltip.css'
import Page404 from './pages/Page404/Page404';
import './components/RadicalChartView/radicalchartview.css'
import './components/LocationChartView/index.css'
import './components/ShortDetailsBox/shortdetails.css'
import ComponentsPage from './pages/DummyPage/ComponentsPage';

function App() {
  const params = useParams();
  const [padding,setPadding] = useState<any>("page");
  const [menu,setMenu] = useState<any>("sidebar");
  const [toggmenu,setToggMenu] = useState(false);
  const [path,setPath] = useState(params)
  const [togglemode,setMode] = useState<String | any>(localStorage.getItem("mpm_mode")?localStorage.getItem("mpm_mode"):localStorage.setItem("mpm_mode","1"))
  const handleResize = ()=>{
    if(window.innerWidth<=727){
      setMenu("menu")
      setPadding("pad-0")
    }else{
      setMenu("sidebar")
      setPadding("page")
    }
  }
  
  useEffect(()=>{
    handleResize()
  },[])
  useEffect(()=>{
    let body = document.querySelector('body');
    if(document.querySelector(".App .light")!==null){
      if(body!==null){
        body.style.background = "var(--light-primary-bgcolor)";
      }
    }else{
      if(body!==null){
        body.style.background = "var(--dark-primary-bgcolor)";
      }
    }
    localStorage.setItem("mpm_mode",togglemode);
  },[togglemode])
  window.addEventListener("resize", handleResize);
  return (
    <div className={togglemode==="1"?"App":"App light"}>
      <Router>
        <Navbar togglemode={togglemode} setMode={setMode} path={path} menu={menu} setMenu={setMenu} toggmenu={toggmenu} setToggMenu={setToggMenu}/>
        <Sidebar menu={menu} setMenu={setMenu} toggmenu={toggmenu} setToggMenu={setToggMenu}/>
        <div className={padding==="page"?"page":"page pad-0"}>
          <Routes>
              <Route path="/country" element={<Home setPath={setPath} togglemode={togglemode}/>} />
              <Route path = "/state/:statename" element = {<State setPath={setPath} togglemode={togglemode}/>}/>
              {/* <Route path = "/componentspage" element = {<ComponentsPage togglemode={togglemode}/>}/>    */}
              <Route path = "/" element = {<Dashboard togglemode={togglemode} setPath={setPath}/>}/>
              <Route path="*" element={<Page404 />} />
          </Routes>
        </div>
        <Footer togglemode={togglemode}/>
      </Router>
    </div>
  );
}

export default App;
