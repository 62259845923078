import React, { useState ,useEffect} from 'react'
import {Link} from 'react-router-dom'
import './search.css'
import {FiSearch} from 'react-icons/fi';

export default function SearchBar({data,togglemode}:any) {
    const [searchvalue,setSearchValue] = useState("");
    const [searchlist,setSearchList] = useState<any>([]);
    const [states_data,setStatesData] = useState<any>([])

    const toCapitalize = (string:String)=>{
        let list = string.split(" ")
        for(let i in list){
            list[i] = list[i].charAt(0) + list[i].slice(1).toLowerCase()
        }
        return list.join(" ");
    }
    const getSearchResults = (e:any)=>{
        let search_value = e.target.value;
        setSearchValue(search_value)
        let data = states_data;
        const search_list = data.filter((d:any)=>{
            return d.region.name.toUpperCase().includes(search_value.toUpperCase());
        });
        if(search_value===""){
            setSearchList([])
        }else{
            setSearchList(search_list)
        }
    }
    useEffect(()=>{
        //API call to get states of India
        if(data){
            setStatesData(data.data)
        }
        // setStatesData(CountryStateData.result.data)
    },[data])
    return (
        <>
            <div className='search'>
                <div className="search-box">
                    <FiSearch />
                    <input  style={togglemode==="1"?{}:{"background":"var(--light-primary-bgcolor)"}} placeholder="Search any state to redirect" value={searchvalue} type="text" onChange={getSearchResults}/>
                </div>
                {searchlist.length===0?null:
                    <div className="results">
                        {searchlist.map((data:any)=>{
                            return (
                                <Link key={'search_'+toCapitalize(data.region.name)} to={`/state/${toCapitalize(data.region.name)}`}>
                                    <div className="result" style={togglemode==="1"?{}:{"color": "var(--light-elem-color)"}}>
                                        <div className="result-left">
                                            <div className="result-name">{toCapitalize(data.region.name)}</div>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}
                    </div>
                }
            </div>
        </>
    )
}
