import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import './chart.css'
import Loader from '../Loader/Loader';

export default function BarChart({ loading,chartData }: any) {
    const [data, setData] = useState<any>(null);
    useEffect(() => {
        setData(
            {
                series: chartData.datasets,
                options: {
                    chart: {
                        height: 350,
                        type: 'area',
                        toolbar: {
                            show: false,
                        }
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    grid: {
                        show: false
                    },
                    markers: {
                        size: 4,
                        colors: undefined,
                        strokeColors: '#fff',
                        strokeWidth: 2,
                        strokeOpacity: 1,
                        strokeDashArray: 0,
                        fillOpacity: 1,
                        discrete: [],
                        shape: "circle",
                        radius: 2,
                        offsetX: 0,
                        offsetY: 0,
                        onClick: undefined,
                        onDblClick: undefined,
                        showNullDataPoints: true,
                        hover: {
                            size: undefined,
                            sizeOffset: 3
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth',
                        width: 2
                    },
                    fill: {
                        type: 'solid',
                        opacity: 1,
                    },
                    yaxis: {
                        show: true,
                        axisBorder: {
                            show: true
                        },
                        axisTicks: {
                            show: true
                        },
                        labels: {
                            show: false
                        },
                        opposite: true
                    },
                    xaxis: {
                        show: true,
                        axisBorder: {
                            show: true,
                        },
                        axisTicks: {
                            show: true,
                        },
                        labels: {
                            show: false
                        }
                    },
                    tooltip: {
                        x: {
                            formatter: function (val:number) {
                                return "Date: " + chartData.labels[val-1]
                            }
                        },
                    },
                },
            }
        )
    }, [chartData])
    return (
        <div className="chart-container">
            {loading?
                <div className='w-100 d-flex justify-content-center text-center align-content-center h-100 mt-5'>
                    <Loader />
                </div>
            :
            data?
            <ReactApexChart options={data.options} series={data.series} type="bar" height={392} />
            : null
            }

        </div>
    )
}
