// importing necessary stuffs from react
import React, {useRef, useEffect, useState} from 'react';
// importing the css file
import './StateVisualizer.css';
// importing necessary stuffs from d3
import {select, geoPath, geoIdentity} from 'd3';
// importing the feature from topojson-client
import {feature } from 'topojson-client';
//useParams from react-router-dom
import { useParams} from 'react-router-dom';

//importing the json data
import {Maps} from '../../projected_maps/allMaps'
import Loader from '../Loader/Loader';

export default function StateVisualizer({city,loading,data,setSelected,setData,togglemode,stateselected,setStateSelected,stats,setStats,ismapname,setMapName,setCity}:any){
    //params will give us the restpective state name
    // const navigate = useNavigate();
    const params:any = useParams()
    //creating a ref to svg
    const svgRef:any = useRef();
    const [maptype,setMaptype] = useState("Total Screened")
    const [rgb,setRGB] = useState([40,167,69])
    const [tooltip,setTooltip] = useState<any>(false);
    const [tooltipcount,setTooltipcount] = useState(0);
    const [count,setCount] = useState(0)
    const [tooltiptopic,setTooltipTopic] = useState("")
    const [name,setName] = useState(stats)
    const [noavailable,setNotAvailable] = useState<any>(null);
    const [fill,setFill] = useState<any>([])
    const [stateData,setStateData] = useState<any>(data)

    // Function Start

    const countCounter = (highest:number)=>{
        setCount(highest)
        // let i = 1
        // let timer = setInterval(() => {
        //     let base = Math.floor(Math.log10(i) + 1);
        //     if(base>=4){
        //         i+=1000;
        //     }else if(base===2){
        //         i += 10;
        //     }else{
        //         i+=100;
        //     }
        //     setCount(i)
        //     if (i >= highest){
        //         setCount(highest)
        //         clearInterval(timer)       
        //     }
        // }, 40);
    }
    // onhover map stroke function

    const mapStroke = (elem:HTMLElement)=>{
        if(togglemode==="1"){
            if(ismapname==="camps"){
                elem.style.stroke = "rgb(138 218 255)"
            }
            if(ismapname==="scans"){
                elem.style.stroke = "rgb(159 255 181)"
            }
            if(ismapname==="pcases"){
                elem.style.stroke = "rgb(255 160 201)"
            }
            if(ismapname==="districts"){
                elem.style.stroke = "rgb(213 213 213)"
            }
        }else{
            if(ismapname==="camps"){
                elem.style.stroke = "rgb(0 33 49)"
            }
            if(ismapname==="scans"){
                elem.style.stroke = "rgb(0 59 13)"
            }
            if(ismapname==="pcases"){
                elem.style.stroke = "rgb(57 0 25)"
            }
            if(ismapname==="districts"){
                elem.style.stroke = "rgb(38 38 38)"
            }
        }
    }

    // onselect map fill function

    const mapFill = (elem:HTMLElement)=>{
        if(togglemode==="1"){
            if(ismapname==="camps"){
                elem.style.fill = "rgb(138 218 255)"
            }
            if(ismapname==="scans"){
                elem.style.fill = "rgb(159 255 181)"
            }
            if(ismapname==="pcases"){
                elem.style.fill = "rgb(255 160 201)"
            }
            if(ismapname==="districts"){
                elem.style.fill = "rgb(213 213 213)"
            }
        }else{
            if(ismapname==="camps"){
                elem.style.fill = "rgb(0 33 49)"
            }
            if(ismapname==="scans"){
                elem.style.fill = "rgb(0 59 13)"
            }
            if(ismapname==="pcases"){
                elem.style.fill = "rgb(57 0 25)"
            }
            if(ismapname==="districts"){
                elem.style.fill = "rgb(38 38 38)"
            }
        }
        elem.style.transition = "0.5s"
    }

    const setMap = (e:any)=>{
        if(e.target.className === "camp-selector"){
            setMapName("camps")
        }
        if(e.target.className === "scan-selector"){
            setMapName("scans")
        }
        if(e.target.className === "pcase-selector"){
            setMapName("pcases")
        }
        if(e.target.className === "districts-selector"){
            setMapName("districts")
        }
    }

    const filterState = (district_name:String)=>{
        // API call for State for district
        let cap_district_name = district_name.toUpperCase();
        let list_districts = stateData?.data??[];
        let result_data = list_districts.filter((dt_nm:any)=>{
            return dt_nm.region.name.toUpperCase()===cap_district_name;
        })
        // console.log(cap_state_name,list_states,result_data.length)
        if(result_data.length===0){
            return null;
        }else{
            return result_data[0]
        }
    }

    const toCapitalize = (string:String)=>{
        let list = string.toString().split(" ")
        for(let i in list){
            list[i] = list[i].charAt(0) + list[i].slice(1).toLowerCase()
        }
        return list.join(" ");
    }

    const getMaxData = (data:any,type:String)=>{
        let max = -1;
        if(type==="pcases"){
            for(let i in data){
                if(data[i]['totalCases']>max){
                max = data[i]['totalCases']
                }
            }
        }
        if(type==="camps"){
            for(let i in data){
                if(data[i]['camps']>max){
                max = data[i]['camps']
                }
            }
        }
        if(type==="scans"){
            for(let i in data){
                if(data[i]['totalScans']>max){
                max = data[i]['totalScans']
                }
            }
        }
        if(type==="districts"){
            for(let i in data){
                if(data[i]['totalGradable']>max){
                max = data[i]['totalGradable']
                }
            }
        }
        return max;
    }

    const getFillData = (data:any,high_value:number,type:String)=>{
        let fill_data = []
        if(high_value!==0){
            if(type==="pcases"){
                for(let i in data){
                    fill_data.push({
                    "key":toCapitalize(data[i].region.name),
                    "fill_perc":data[i]['totalCases']/high_value??0
                    })
                }
            }
            if(type==="camps"){
                for(let i in data){
                    fill_data.push({
                    "key":toCapitalize(data[i].region.name),
                    "fill_perc":data[i]['camps']/high_value??0
                    })
                }
            }
            if(type==="scans"){
                for(let i in data){
                    fill_data.push({
                    "key":toCapitalize(data[i].region.name),
                    "fill_perc":data[i]['totalScans']/high_value??0
                    })
                }
            }
            if(type==="districts"){
                for(let i in data){
                    fill_data.push({
                    "key":toCapitalize(data[i].region.name),
                    "fill_perc":data[i]['totalGradable']/high_value??0
                    })
                }
            }
        }else{
            for(let i in data){
                fill_data.push({
                "key":toCapitalize(data[i].region.name),
                "fill_perc":0
                })
            }
        }
        return fill_data
    }
    // Function end

    useEffect(()=>{
        if(city===null){
            setStateData(data);
        }
    },[city,data])
    useEffect(()=>{
        if(stateData && city===null){
            if(ismapname==="pcases"){
                setMaptype("Total Positive Cases")
                setRGB([255,7,58])
                let maxCases = getMaxData(stateData.data,ismapname)
                let fillData = getFillData(stateData.data,maxCases,ismapname);
                setFill(fillData)
            }
            if(ismapname==="scans"){
                setRGB([40,167,69])
                setMaptype("Total Screened")
                let maxScans = getMaxData(stateData.data,ismapname)
                let fillData = getFillData(stateData.data,maxScans,ismapname);
                setFill(fillData)
            }
            if(ismapname==="camps"){
                setRGB([0,123,255])
                setMaptype("Total Camps")
                let maxCamps = getMaxData(stateData.data,ismapname)
                let fillData = getFillData(stateData.data,maxCamps,ismapname);
                setFill(fillData)
            }
            if(ismapname==="districts"){
                setRGB([108,117,125])
                setMaptype("Total Gradable")
                let maxGrad = getMaxData(stateData.data,ismapname)
                let fillData = getFillData(stateData.data,maxGrad,ismapname);
                setFill(fillData)
            }
            if(ismapname==="camps"){
                countCounter(stats?.camps??0)
            }
            if(ismapname==="scans"){
                countCounter(stats?.totalScans??0)
            }
            if(ismapname==="pcases"){
                countCounter(stats?.totalCases??0)
            }
            if(ismapname==="districts"){
                countCounter(stats?.totalGradable??0)
            }
            if(stats!==null && stats!==params.statename){
                setName(toCapitalize(stats?.region?.name??params.statename))
            }else{
                setStats(params.statename)
            }
        }
    },[ismapname,stats,stateData])

    useEffect(()=>{
      // console.log(stateselected)
        const current:any = svgRef.current;
        const svg = select(current);
        //State selection in map
        const stname:any = params.statename.split(" ").join("")
        const A:any = Maps[stname].objects.districts
        const B:any = Maps[stname];
        const districts:any = feature(B, A);
        //generating the state array
        //the pathGenerator function will be generating the d attributes for all the path elements
        const pathGenerator = geoPath(geoIdentity());
        
        const showMap = ()=>{
            svg.select('.region')
            .selectAll(".paths")
            .data(districts.features)
            .enter()
            .append('path')
            .attr('class', (d:any)=>{
                let st = d.properties.district.split(" ")
                st = st.join("")
                return `paths ${st}`
            })
            .attr('d', (district:any) => pathGenerator(district))
            .attr('stroke-width', 1)          
            .attr('stroke-opacity', 0.7)
            .attr('fill', (d:any,i:any)=>{
                let data = fill.filter((st:any)=>{
                    return st.key === d.properties.district;
                })
                if(data.length===1){
                    return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${data[0].fill_perc})`
                }
                return `transparent`
            })
            .attr('stroke', `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`)
            .style('cursor', 'pointer')
            .attr('pointer-events', 'all')
            .on('click',showStat)
            .on('mouseover',(e:any,d:any)=>{
                let elem = document.querySelector<HTMLElement>(`.${e.target.classList[1]}`)
                if(elem!=null){
                    if(stateselected===null){
                        let getDistrict = filterState(d.properties.district)
                        if(getDistrict===null){
                            setNotAvailable(`No data available for ${d.properties.district}`)
                            setTimeout(() => {
                                setNotAvailable(null)
                            }, 3000);
                            setSelected(null)
                        }else{
                            setStats(getDistrict)
                            setSelected(getDistrict.region.name)
                        }
                        mapStroke(elem)
                        elem.style.strokeWidth = '1px';
                        elem.style.strokeOpacity= '1'
                    }
                }
            })
            .on('mouseout',()=>{
                if(stateselected===null){
                    svg.select('.region')
                        .selectAll('.paths')
                        .remove()
                    showMap()
                }
            })
        }
        const showStat = (e:any,d:any)=>{
            let getDistrict = filterState(d.properties.district)
            if(getDistrict){
                if(!tooltip && tooltipcount===0){
                    let count = 1;
                    setTooltipTopic("One click to see details of this district")
                    setTooltip(true);
                    let timer = setInterval(()=>{
                        setTooltipTopic("Click again on that district to deselect district")
                        count += 1
                        if(count>2){
                            setTooltip(false)
                            setTooltipTopic("")
                            count = 1;
                            clearInterval(timer)
                        }
                    },3000)
                }
                setTooltipcount(tooltipcount+1);
                if(tooltipcount>=4){
                    setTooltip(false);
                    setTooltipcount(0)
                }
                if(stateselected===null){
                    setCity(toCapitalize(getDistrict.region.name))
                    setStats(getDistrict)
                    setStateSelected(e.target.classList[1])
                    setSelected(getDistrict.region.name)
                }else{
                    if(e.target.classList[1]===stateselected){
                        // setStats(params.statename)
                        setStateSelected(null)
                        setData(null)
                        setCity(null)
                        setSelected(null)
                    }
                }
            }else{
                setNotAvailable(`No data available for ${d.properties.district}`)
                setTimeout(() => {
                    setNotAvailable(null)
                }, 3000);
            }
        }

        svg.select('.region')
            .selectAll('.paths')
            .remove()
        showMap()
        if(stateselected!==null){
            svg.select('.region')
                .selectAll('.paths')
                .remove()
            showMap()
            let elem = document.querySelector<HTMLElement>(`.${stateselected}`)
            if(elem!=null){
                mapFill(elem)
                elem.style.strokeWidth = '1px';
                elem.style.strokeOpacity= '1'
            }
        }
        svg.attr("viewBox" , "-350 0 1200 500" ).attr("preserveAspectRatio" , "xMidYMid meet").attr("pointer-events"  , 'auto');
    },[svgRef,tooltip,ismapname,stateselected,togglemode,tooltipcount,rgb,fill,data,stateData])

    return (
        <div className='wrapper zoom'>
            <div className="short-data" style={{color:`rgb(${rgb[0]},${rgb[1]},${rgb[2]})`}}>
                <div className="india-data">
                <p>{stats===params.statename?params.statename:name}</p>
                {loading?<Loader />:<h2>{count}</h2>}
                </div>
                <div className="states-data">
                <p>{maptype}</p>
                <div className="map-selector">
                    <span>Type of map</span>
                    <div className="selectors">
                    <div className={ismapname==="camps"?"camp-selector active":"camp-selector"} onClick={setMap}></div>
                    <div className={ismapname==="scans"?"scan-selector active":"scan-selector"} onClick={setMap}></div>
                    <div className={ismapname==="pcases"?"pcase-selector active":"pcase-selector"} onClick={setMap}></div>
                    <div className={ismapname==="districts"?"districts-selector active":"districts-selector"} onClick={setMap}></div>
                    </div>
                </div>
                </div>
            </div>
            <svg ref={svgRef} className="districtSvg" >
                <g className="region" fill='red'></g>
                <g className="circles" fill='red'></g>
                <g className="texts" fill='red'></g>
            </svg>
            <div className="map_tooltip">
                {tooltip?<p>{tooltiptopic}</p>:null}
                {noavailable!==null?<p>{noavailable}</p>:null}
            </div>
        </div>
        
    )

};

