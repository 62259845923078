import WestBengal from './WestBengal.json'
import TamilNadu from './TamilNadu.json'
import Karnataka from './Karnataka.json'
import Maharashtra from './Maharashtra.json'
import UttarPradesh from './UttarPradesh.json'
import Rajasthan from './Rajasthan.json'
import Assam from './Assam.json'
import MadhyaPradesh from './MadhyaPradesh.json'
import Bihar from './Bihar.json'
import Nagaland from './Nagaland.json'
import Delhi from './Delhi.json'
import Kerala from './Kerala.json'
import Uttarakhand from './Uttarakhand.json'
import Gujarat from './Gujarat.json'
import Haryana from './Haryana.json'


export const DistrictState:any = {
    WestBengal,
    TamilNadu,
    Karnataka,
    UttarPradesh,
    Maharashtra,
    Rajasthan,
    Assam,
    MadhyaPradesh,
    Bihar,
    Nagaland,
    Delhi,
    Kerala,
    Uttarakhand,
    Gujarat,
    Haryana
}