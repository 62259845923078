
export default function Increase({data}:any) {
    return (
        <div className='change increase'>
            <svg width="27" height="39" viewBox="0 0 27 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 12.5V38.5L13.5 32.5L19 38.5V12.5H26.2344C26.5073 12.5 26.6385 12.1653 26.4383 11.9799L13.7718 0.251633C13.6184 0.109638 13.3816 0.109638 13.2282 0.251633L0.561739 11.9799C0.361519 12.1653 0.492693 12.5 0.76556 12.5H8Z" fill="#06949A"/>
            </svg>
            
            <span>{data.toFixed(2) +' %'}</span>
        </div>
    )
}
