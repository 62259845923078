import React, { useEffect } from 'react'
import './navbar.css'
import {Link,useNavigate} from 'react-router-dom'
import {IoArrowBack} from 'react-icons/io5'
import {AiOutlineHome} from 'react-icons/ai'
import {BsMoonStarsFill,BsSunFill} from 'react-icons/bs';
import { Tooltip } from 'react-tooltip'

export default function Navbar({togglemode,setMode,path,menu,setMenu,setToggMenu,toggmenu}:any) {
  const navigate = useNavigate()
  // const toggleMenu= ()=>{
  //   if(toggmenu){
  //     setToggMenu(false)
  //   }else{
  //     setToggMenu(true);
  //   }
  // }
  const toggleMode = ()=>{
    if(togglemode==="1"){
      setMode("0")
    }else{
      setMode("1")
    }
  }
  function handleClick() {
    navigate(0); // navigate to same path
  }
  return (
    <header className={togglemode==="1"?'navbar position-fixed':'navbar light position-fixed'}>
      
      {window.location.pathname==="/"?
        <div className="d-flex gap-2 align-items-center">
          <Link to='/'>
            <AiOutlineHome data-tooltip-content="Home" className='effect_icon home_icon' onClick={() => {
    navigate(0);
  }}/>
            <Tooltip anchorSelect='.home_icon'/></Link>
          <div>
            <Link to='/country'  className="map_page">
              {togglemode==="1"?<img data-tooltip-content="Map" className='map' src="light_india.png" />:<img data-tooltip-content="Map" className='map' src="india.png"/>}
              <Tooltip anchorSelect='.map'/>
            </Link>
          </div>
        </div>
      :
        <div className='d-flex gap-2 align-items-center'>
          <Link to='/'>
            <AiOutlineHome data-tooltip-content="Home" className='effect_icon home_icon' />
            <Tooltip anchorSelect='.home_icon'/>
          </Link>
          <div style={{cursor:'pointer'}} onClick={()=>navigate(-1)}>
            <IoArrowBack data-tooltip-content="Back" className='effect_icon arrow'/>
            <Tooltip anchorSelect='.arrow'/>
          </div>
        </div>
      }
      <Link to='/'>
        <p>{window.location.pathname==="/"?"Dashboard":"Map view"}</p>
      </Link>
      {togglemode==="1"?<BsMoonStarsFill color='white' data-tooltip-content="Light mode" className='toggle_button' onClick={toggleMode}/>:<BsSunFill data-tooltip-content="Dark mode" className='toggle_button inc-size' onClick={toggleMode}/>}
      <Tooltip anchorSelect='.toggle_button'/>
        {/* {menu==="menu"?
          <div className='topmenu' onClick={toggleMenu}>
              <span>{toggmenu?"Close":"Menu"}</span>
          </div>
        :null} */}
    </header>
  )
}
