import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import './map.css'
import json_data from '../../projected_maps/geojson/india_geojson.json'

mapboxgl.accessToken = String(process.env.REACT_APP_MAPBOX_TOKEN);

export default function ChoroplethMap({fill,dateRange,togglemode,data,ismapname}:any) {
    const mapContainerRef = useRef<any>(null);
    const map = useRef<any>(null);
    const [jsonData,setJsondata] = useState<any>(null);
    const [stateDetails,setStatedetails] = useState<any>(null);
    const navigate = useNavigate()
    const [mapColors,setMapcolors] = useState<string[] | null>(null)
    const [zoom, setZoom] = useState<any>(3.5);
    const MIN_ZOOM = 2.8
    const MAX_ZOOM = 25
    useEffect(()=>{
        if(ismapname==="scans"){
            setMapcolors([
                "#f7fcfd",
                "#e5f5f9",
                "#ccece6",
                "#99d8c9",
                "#66c2a4",
                "#41ae76",
                "#238b45",
                "#006d2c",
                "#00441b"
            ])
        }else if(ismapname==="pcases"){
            setMapcolors([
                "#fff5f0",
                "#fee0d2",
                "#fcbba1",
                "#fc9272",
                "#fb6a4a",
                "#ef3b2c",
                "#cb181d",
                "#a50f15",
                "#67000d"
            ])
        }else if(ismapname==="camps"){
            setMapcolors([
                "#f7fbff",
                "#deebf7",
                "#c6dbef",
                "#9ecae1",
                "#6baed6",
                "#4292c6",
                "#2171b5",
                "#08519c",
                "#08306b"
            ])
        }else if(ismapname==="districts"){
            setMapcolors([
                "#ffffff",
                "#f0f0f0",
                "#d9d9d9",
                "#bdbdbd",
                "#969696",
                "#737373",
                "#525252",
                "#252525",
                "#000000"
            ])
        }
    },[ismapname])
    useEffect(()=>{
        json_data.features.forEach((feature:any)=>{
            let properties = feature.properties
            // console.log(properties,data.data)
            let map_data = data.data.filter((state:any)=>{
                return state.region.name === properties.ST_NM
            })
            
            let filterValue=0;
            let type;
            let color;
            let percValue;
            if(ismapname==="scans"){
                filterValue = map_data[0]?.totalScans ?? 0
                type = data.meta.totalScans
                color = "rgb(40, 167, 69)"
                // percValue = fill.filter((perc:any)=>{
                //     return perc.key === properties.ST_NM
                // });
                // percValue = percValue[0]?.fill_perc ?? 0
            }else if(ismapname==="pcases"){
                filterValue = map_data[0]?.totalCases ?? 0
                type = data.meta.totalCases;
                color = "rgb(255, 7, 58)"
            }else if(ismapname==="camps"){
                filterValue = map_data[0]?.camps ?? 0
                type = data.meta.camps;
                color = "rgb(0, 123, 255)"
            }else if(ismapname==="districts"){
                filterValue = map_data[0]?.totalGradable ?? 0
                type = data.meta.totalGradable;
                color = "rgb(108, 117, 125)"
            }
            percValue = fill.filter((perc:any)=>{
                return perc.key === properties.ST_NM
            });
            percValue = percValue[0]?.fill_perc ?? 0
            properties["type"] = type
            properties["cases"] = filterValue
            properties["color"] = color
            properties["percValue"] = percValue*100
        })
        setJsondata(json_data)
    },[data,ismapname,fill])

    useEffect(() => {
        map.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: togglemode==="1"?process.env.REACT_APP_MAP_DARK:process.env.REACT_APP_MAP_LIGHT,
            center: [78.9629, 22.5937],
            zoom: zoom,
            pitch: 0,
            bearing: 0,
            attributionControl: false,
            minZoom:MIN_ZOOM,
            maxZoom:MAX_ZOOM
        });
        let popup = new mapboxgl.Popup({
            offset: [0, -7],
            closeButton: false,
            closeOnClick: false
        });

        // Event listener for scroll-based zooming
        const handleScroll = (e: { preventDefault: () => void; deltaY: number; }) => {
            e.preventDefault();
            const delta = Math.sign(e.deltaY);

            const zoom = map.current.getZoom();
            const targetZoom = zoom - delta;

            map.current.setZoom(targetZoom);
        };

        // Attach scroll event listener to the map container
        mapContainerRef.current.addEventListener('wheel', handleScroll, { passive: false });

        // Add a GeoJSON source
        map.current.on('load', () => {
            map.current.addSource('india-choropleth', {
                'type': 'geojson',
                'data': jsonData
            });

            // Add a new layer to visualize the polygon.
            if(mapColors){

                map.current.addLayer({
                    'id': 'cases',
                    'type': 'fill',
                    'source': 'india-choropleth',
                    'paint': {
                        'fill-color': {
                            type: "interval",
                            property: "percValue",
                            stops: [
                                [0, mapColors[0]],
                                [10, mapColors[1]],
                                [20, mapColors[2]],
                                [30, mapColors[3]],
                                [40, mapColors[4]],
                                [50, mapColors[5]],
                                [60, mapColors[6]],
                                [70, mapColors[7]],
                                [80, mapColors[8]],
    
                            ]
                        },
                    }
                });
                map.current.addLayer({
                    'id': 'state-borders',
                    'type': 'line',
                    'source': 'india-choropleth',
                    'layout': {},
                    'paint': {
                        'line-color': '#000000',
                        'line-width': 0.5
                    }
                });
            }

            // make a pointer cursor
            map.current.getCanvas().style.cursor = 'default';


            // define layer names
            const layers = [
                '0%',
                '<10%',
                '<20%',
                '<30%',
                '<40%',
                '<50%',
                '<60%',
                '<70%',
                '>80%'
            ];
            const colors = mapColors;

            // create legend
            if(colors){
                const legend: any = document.getElementById('legend');
                while (legend.firstChild) {
                    legend.removeChild(legend.lastChild);
                }
                layers.forEach((layer, i) => {
                    const color = colors[i];
                    const item = document.createElement('div');
                    const key = document.createElement('span');
                    key.className = 'legend-key';
                    key.style.backgroundColor = color;
    
                    const value = document.createElement('span');
                    value.innerHTML = `${layer}`;
                    item.appendChild(key);
                    item.appendChild(value);
                    legend.appendChild(item);
                });
            }

            // change info window on hover
            map.current.on('mousemove', (event: { point: any; }) => {
                
                const states = map.current.queryRenderedFeatures(event.point, {
                    layers: ['cases']
                });
                if(states.length){
                    setStatedetails({
                        state:states[0].properties.ST_NM,
                        type:states[0].properties.type,
                        cases:states[0].properties.cases,
                        color:states[0].properties.color
                    })
                }else{
                    setStatedetails(null)
                }
            });

            // onclick redirect
            map.current.on('click', 'cases', (e:any) => {
                if(e.features[0].properties.cases> 0){
                    map.current.getCanvas().style.cursor = 'pointer';
                    navigate(`/state/${e.features[0].properties.ST_NM}`)
                }
            });
            // let zoomControl = new CustomZoomControl();
			// map.current.addControl(zoomControl, 'top-right');

			map.current.on('zoom', function () {
				setZoom(map.current.getZoom())
			});
        });
        return () => {
            // Clean up the map instance and remove the scroll event listener
            map.current.remove();
            // mapContainerRef.current.removeEventListener('wheel', handleScroll);
        };
    }, [jsonData,ismapname,togglemode,dateRange,fill]);
    return (
        <>
            <div id="map" ref={mapContainerRef} >
                <div className="map-overlay zoom-slider" style={{
                    color:togglemode==="1"?"white":"black",
                    background:togglemode==="1"?"#2e324a":"white"
                }}>
                    <div className="d-flex justify-content-between">
                        <span>Zoom out</span>
                        <span>Zoom In</span>
                    </div>
                    <input className=""
                    type="range"
                    min={MIN_ZOOM}
                    max={MAX_ZOOM}
                    value={zoom}
                    onChange={(event) => {
                        const newZoom = parseFloat(event.target.value);
                        map.current.setZoom(newZoom);
                        setZoom(newZoom);
                    }}
                    />
                </div>
                <div className="map-overlay" id="features" style={{
                    boxShadow:togglemode==="1"?"var(--box-shadow-1)":"var(--box-shadow-1)",
                    background:togglemode==="1"?"#2e324a":"white"
                }}>
                    
                    <div id="pd">
                        {stateDetails===null ? <p style={{
                                        fontWeight:600,
                                        color:togglemode==="1" ? "white" : "black",
                                        textAlign:"center",
                                        margin:"0"
                                    }
                         } >Hover over a state!</p> :
                            <div>
                                <h5 style={{
                                        fontWeight:700,
                                        color:togglemode==="1" ? "white" : "black"
                                    }
                                }>{stateDetails.state}</h5>
                                <h6 style={{color:stateDetails.color,fontWeight:600}}>{stateDetails.type}</h6>
                                <h6 style={{fontWeight:600,color:togglemode==="1" ? "white" : "black"}}>{stateDetails.cases}</h6>
                            </div>
                        }
                        
                    </div>
                </div>
                <div className={"map-overlay"+` ${togglemode==="1" ? "dark":"light"}`} id="legend"></div>
            </div>
        </>
    );
}

