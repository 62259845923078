import React,{useEffect,useState} from 'react'
import {Link,useLocation} from 'react-router-dom'
import Links from './Links'
import './sidebar.css'

export default function Navbar({menu,setMenu,setToggMenu,toggmenu}:any) {
  
  const location = useLocation();
  const [ishome,setHome] = useState(false);
  const [isstarted,setStarted] = useState(false);
  useEffect(()=>{
      switch (location.pathname) {
      case '/':
          setHome(true)
          setStarted(false);
          break;
      case '/getstarted':
          setStarted(true);
          setHome(false);
          break;
      default:
          break;
      }

  },[location.pathname])
  
  return (
    <div className={menu}>
        {menu==="sidebar"?<Links ishome={ishome} isstarted={isstarted} />:
          null
        }
        {!toggmenu?null:
        menu==="menu"?
        <div className='toggmenu-links'>
          <Link className={ishome?'active':''} to="/">
              Home
          </Link>
          <Link className={isstarted?'active':''} to="/getstarted">
              Get Started
          </Link>
      </div>:null}
    </div>
  )
}
