import React from "react";
import "./footer.css";

export default function Footer({ togglemode }: any) {
    return (
        <footer
            className={
                togglemode === "1"
                    ? "footer d-flex justify-content-between"
                    : "footer light d-flex justify-content-between"
            }
        >
            <div className="copyright">
                <p>
                    &#169; 2024 | Powered by{" "}
                    <a
                        href={process.env.REACT_APP_COPYRIGHT_LINK}
                        rel="noreferrer"
                        target="_blank"
                    >
                        {process.env.REACT_APP_COPYRIGHT}
                    </a>{" "}
                </p>
            </div>
            <p className="p-0 m-0 f-900">Million Person March</p>
        </footer>
    );
}
