import React from 'react';
import './loader.css'

export default function Loader() {
    return (
        <div className='d-flex justify-content-center w-100'>
            <span className="loader"></span>
        </div>
    )
}
